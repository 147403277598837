<template>
    <div class="con">
        <div class="right">
            <div class="switchnew">
                <div class="qiantai">
                    <div class="switchnew-title">
                        <div class="flex flex-ac">
                            <span class="ft-30 ft-bold mg-rt60">前台打印机</span>
                            <div class="flex flex-ac">
                                <img class="mg-rt20" src="@/static/images/receipt-paper.png" style="width: 5.5rem; height: 5.5rem;" />
                                <button class="switchnew-add-btn" @click="openBuyPaper">购买小票纸</button>
                            </div>
                        </div>
                        <button class="switchnew-add-btn flex flex-ac" @click="jumpTo('qiantai', 1)"><van-icon name="add" size="2.8rem" color="#fff" class="mg-rt10" />添加打印机</button>
                    </div>
                    <div class="printlist">
                        <template v-if="qiantaiPrints.length > 0">
                            <div class="printitem" v-for="item in qiantaiPrints" :key="item.id" @click="jumpTo('qiantai', 2, item)">
                                <div class="flex flex-ac">
                                    <van-icon name="printer" size="3.5rem" color="#1588F5" class="mg-rt30" />
                                    <div class="mg-rt50">{{ item.name }}</div>
                                    <div class="mg-rt50">{{ item.printer_type | PrintType }}</div>
                                    <div class="mg-rt50" v-if="item.printer_type == 2">{{ item.ip }}</div>
                                    <div class="mg-rt50 thistag" v-if="item.bind_status == 1">本机</div>
                                    <template v-if="item.bind_status == 1 && item.printer_type == 4">
                                        <div class="mg-rt50 color-3DB956" v-if="usbConnectStatus.code == 1 || mtPrinterStatus.code == 1">连接正常</div>
                                        <div class="mg-rt50 color-e60012" v-else>连接异常</div>
                                    </template>
                                </div>
                                <div class="ft-24 color-333 flex flex-ac">
                                    <van-icon name="edit" color="#333" size="3rem" class="mg-rt15" />
                                    <span>编辑</span>
                                </div>
                            </div>
                        </template>
                        <template v-if="qiantaiPrints.length == 0">
                            <div class="nullStyle">
                                <p class="text-center ft-24 color-999">暂无前台打印机~</p>
                            </div>
                        </template>
                    </div>

                    <div class="printset-switch-item">
                        <div class="mg-bt40">
                            <span class="mg-rt7">前台打印票据</span>
                            <span class="ft-26 color-666">（前台打印机将按照所选单据进行打印）</span>
                        </div>
                        <div class="printset">
                            <div class="flex flex-ac mg-rt140 mg-bt40">
                                <van-checkbox v-model="print_receipt" icon-size="3.2rem" shape="square" @click="elecEvent(3, $event, 'print_receipt')">结账单</van-checkbox>
                                <van-icon name="search" size="3.2rem" class="mg-lt10" @click="previewPrint(1)" />
                            </div>
                            <div class="flex flex-ac mg-rt140 mg-bt40">
                                <van-checkbox v-model="recharge_print" icon-size="3.2rem" shape="square" @click="elecEvent(10, $event, 'recharge_print')">充值单</van-checkbox>
                                <van-icon name="search" size="3.2rem" class="mg-lt10" @click="previewPrint(2)" />
                            </div>
                            <div class="flex flex-ac mg-rt140 mg-bt40">
                                <van-checkbox v-model="is_handover_print" icon-size="3.2rem" shape="square" @click="elecEvent(11, $event, 'is_handover_print')">交接班</van-checkbox>
                                <van-icon name="search" size="3.2rem" class="mg-lt10" @click="previewPrint(3)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="switchnew mg-tp20">
                <div class="qiantai">
                    <div class="switchnew-title">
                        <div class="flex flex-ac">
                            <span class="ft-30 ft-bold mg-rt60">标签打印机</span>
                        </div>
                        <button v-if="tag_printers.length < 1" class="switchnew-add-btn flex flex-ac" @click="jumpTo('label', 1)"><van-icon name="add" size="2.8rem" color="#fff" class="mg-rt10" />添加打印机</button>
                    </div>
                    <div class="printlist">
                        <template v-if="tag_printers.length > 0">
                            <div class="printitem" v-for="item in tag_printers" :key="item.id" @click="jumpTo('label', 2, item)">
                                <div class="flex flex-ac">
                                    <van-icon name="printer" size="3.5rem" color="#1588F5" class="mg-rt30" />
                                    <div class="mg-rt50">{{ item.name }}</div>
                                    <div class="mg-rt50">{{ item.printer_type | PrintType }}</div>
                                    <div class="mg-rt50 color-3DB956" v-if="item.printer_type == 4 && labelConnectStatus.code == 1">连接正常</div>
                                    <div class="mg-rt50 color-e60012" v-if="item.printer_type == 4 && labelConnectStatus.code != 1">连接异常</div>
                                    <div class="mg-rt50 color-3DB956" v-if="item.printer_type == 3 && blelabelConnectStatus.code == 1">连接正常</div>
                                    <div class="mg-rt50 color-e60012" v-if="item.printer_type == 3 && blelabelConnectStatus.code != 1">连接异常</div>
                                </div>
                                <div class="ft-24 color-333 flex flex-ac">
                                    <van-icon name="edit" color="#333" size="3rem" class="mg-rt15" />
                                    <span>编辑</span>
                                </div>
                            </div>
                        </template>
                        <template v-if="tag_printers.length == 0">
                            <div class="nullStyle">
                                <p class="text-center ft-24 color-999">暂无标签打印机~</p>
                            </div>
                        </template>
                    </div>

                    <div class="printset-switch-item">
                        <div>
                            <button class="switchnew-add-btn mg-rt50" @click="jumpTo('manageProducts')">打印商品标签</button>
                            <button class="switchnew-add-btn" @click="jumpTo('labelSetting')">模板设置</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <popupOfDialog
            v-if="showDialog"
            :type="dialogType"
            :title="dialogTitle"
            :message="dialogMsg"
            :data="dialogData"
            @closePopDialog="closePopDialog"
            @confirmPopDialog="confirmPopDialog"
            :showCancelBtn="showCancelBtn"
            :showConfirmBtn="showConfirmBtn"
            :list="dialogList"
        ></popupOfDialog>

        <receipt-preview
            v-if="isShowPreview"
            :data="printData"
            @closePreview="closePreview"
            :receipttype="receipttype"
            :size="58"
        ></receipt-preview>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import {
    deviceInfo,
} from '@/utils/outPrint'
import { formatTime, getPrinterType } from '@/utils/util';
import { checkLabelPrintStatus, checkTagConnect, checkUsbConnect, getBleConnectStatus, getPrinterStatus } from '@/utils/outPrint';
import receiptPreview from '@/views/component/receiptPreview.vue'; // 打印小票预览
import { checkPrintType } from '../../utils/util';
export default {
    name: "setPrintType",
    components: {
        popupOfDialog,
        'receipt-preview': receiptPreview,
    },
    data() {
        return {
            qiantaiPrints: [], // 前台打印机
            device_ip: '',
            device_mac: '',
            showDialog: false,
            dialogTitle: '',
            dialogMsg: '',
            dialogType: '',
            dialogData: {},
            disab: false,
            timer1: null,
            timer1count: 0,
            print_receipt: false, // 是否打印结账单
            isShowPreview: false, // 是否展示预览
            printData: {},
            receipttype: '',
            showCancelBtn: true,
            showConfirmBtn: true,
            dialogList: [],
            recharge_print: false, // 充值单
            is_handover_print: false,
            tag_printers: [],
            timer2: null,
            timer2count: 0,
        }
    },
    computed: {
        ...mapGetters({ shop: 'shop' }),
        usbConnectStatus() {
            console.log('this.$store.state.usbConnectStatus', this.$store.state.usbConnectStatus);
            return this.$store.state.usbConnectStatus;
        },
        isApp() {
            return localStorage.nowDevice || '';
        },
        mtPrinterStatus() {
            return this.$store.state.mtPrinterStatus;
        },
        blelabelConnectStatus() {
            return this.$store.state.blelabelConnectStatus;
        },
        labelConnectStatus() {
            return this.$store.state.labelConnectStatus;
        },
    },
    filters: {
        // 打印机类型 1，summi打印机  2，网络打印机  3，蓝牙打印机
        PrintType(val) {
            const obj = {
                1: 'SUNMI打印机',
                2: '网口打印机',
                3: '蓝牙打印机',
                4: 'USB打印机',
                5: '驱动打印机',
                6: '大趋云打印机',
                // 7: 'USB打印机', // 标签打印机安卓
                // 8: '驱动打印机', // 标签打印机Windows
                // 9: '蓝牙打印机', // 标签打印机IOS 暂时没用
                10: '内置打印机',
                7: '佳博云打印机',
            }
            return obj[val] || '';
        },
        // 打印机状态： 1正常 2异常
        PrinterStatus(val) {
            const obj = {
                1: '连接正常',
                2: '连接异常'
            }
            return obj[val] || '';
        }
    },
    methods: {
        openBuyPaper() {
            this.dialogType = '1';
            this.dialogTitle = '购买小票纸';
            this.dialogMsg = '';
            this.dialogData = {};
            this.dialogList = [
                {
                    title: '58小票纸',
                    msgs: ['请扫描下方二维码购买58mm小票纸，规格为32卷/箱，每箱售价75元（包邮），最低购买数量1箱。'],
                    image: require('@/static/images/paper-75.png'),
                },
                {
                    title: '80小票纸',
                    msgs: ['请扫描下方二维码购买80mm小票纸，规格为24卷/箱，每箱售价75元（包邮），最低购买数量1箱。'],
                    image: require('@/static/images/paper-75.png'),
                }
            ]
            this.showCancelBtn = false;
            this.showConfirmBtn = false;
            this.showDialog = true;
        },
        getPrintSetting() {
            // 1,print_food 前台是否打印做菜单，2,hc_print_food 后厨是否打印做菜单,3,print_receipt 是否打印结账单
            // 4,print_menu 是否打印点菜单  5,print_pass_menu 是否打印传菜单   6,print_return_menu 是否打印退菜单
            // 0 不打印   1 打印
            this.$api.dt_getPrintSet().then(res => {
                if (res.code == 1) {
                    this.print_receipt = res.data.print_receipt == 1;
                    this.recharge_print = res.data.recharge_print == 1;
                    this.is_handover_print = res.data.is_handover_print == 1;
                }
            })
        },
        closePreview() {
            this.isShowPreview = false;
        },
        previewPrint(type) {
            this.receipttype = type;
            this.isShowPreview = true;
        },
        elecEvent(type, val, name) {
            // console.log(type, val, this[`${name}`]);
            
            /**
             * type 1,print_food 前台是否打印做菜单，2,hc_print_food 后厨是否打印做菜单,3,print_receipt 是否打印结账单
             *      4,print_menu 是否打印点菜单  5,print_pass_menu 是否打印传菜单   6,print_return_menu 是否打印退菜单
             *      7 merge_print 后厨做菜单是否合并 8 change_desk_print 换台通知单 10充值单 11 is_handover_print 是否打印交接班小票
             * status  0 不打印  1 打印
             */
            let data = {
                type: type,
                status: this[`${name}`] ? 1 : 0,
            }
            this.$api.dt_PrintSet(data).then(res => {
                if (res.code == 1) {
                    this.$toast('设置成功，门店设置已更新');
                    this.getPrintSetting();
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        confirmPopDialog(data) {
            this.$router.push({ 
                name: 'addQiantaiPrint',
                query: {
                    name: 'addQiantaiPrint',
                    active: 4,
                }
            })
            this.closePopDialog();
        },
        closePopDialog(data) {
            this.showDialog = false;
        },
        // 跳转
        jumpTo(type, action, item) {
            // this.$toast('敬请期待！')
            if (type == 'qiantai') { // 添加前台打印机
                if (action == 1) {
                    if (this.$store.state.bindStatus == 1) {
                        this.dialogTitle = '提示';
                        this.dialogMsg = '当前设备已经添加过一台打印机，继续添加将会删除已添加的打印机，并将当前设备重新绑定到添加的打印机上，确认继续添加吗？'
                        this.showDialog = true;
                        return;
                    }
                    this.$router.push({ 
                        name: 'addQiantaiPrint',
                        query: {
                            name: 'addQiantaiPrint',
                            active: 4,
                        }
                    })
                } else {
                    this.$router.push({ 
                        name: 'addQiantaiPrint', 
                        query: { 
                            id: item.id, 
                            bind_status: item.bind_status,
                            name: 'editQiantaiPrint',
                            active: 4,
                        } 
                    })
                }
            } else if (type == 'label') { // 标签打印机
                if (action == 1) {
                    this.$router.push({
                        name: 'addLabelPrint',
                        query: {
                            name: 'addLabelPrint',
                            active: 4,
                        }
                    });
                } else {
                    this.$router.push({
                        name: 'addLabelPrint', 
                        query: { 
                            id: item.id,
                            name: 'editLabelPrint',
                            active: 4,
                        }
                    });
                }
            } else if (type == 'labelSetting') {
                this.$router.push({
                    name: 'labelSetting',
                    query: {
                        name: 'labelSetting',
                        active: 4,
                    }
                })
            } else if (type == 'manageProducts') {
                this.$router.replace({
                    name: 'manageProducts',
                    query: {
                        name: 'manageProducts',
                    }
                })
            }
        },
        getData() {
            this.$api.dt_printerList({
                device_mac: this.$store.state.deviceMac
            }).then(async res => {
                if (res.code == 1) {
                    this.qiantaiPrints = res.data.front_printer || [];
                    
                    const index = this.qiantaiPrints.findIndex(e => e.bind_status == 1);
                    if (index > -1) {
                        this.$store.commit('updateBindStatua', 1);
                        localStorage.setItem('printer_id', this.qiantaiPrints[index].id || 0);
                        if ((this.isApp == 'android' || this.isApp == 'mt_android') && this.qiantaiPrints[index].printer_type == 4) {
                            this.timer1count = 0;
                            checkUsbConnect();
                            this.timer1 = setInterval(() => {
                                this.timer1count++;
                                checkUsbConnect(this.timer1count);
                                if (this.timer1count = 1) {
                                    this.timer1count = 0;
                                }
                            }, 15000);
                        }
                        if (this.isApp == 'mt_android' && this.qiantaiPrints[index].printer_type == 10) {
                            this.timer1count = 0;
                            getPrinterStatus();
                            this.timer1 = setInterval(() => {
                                this.timer1count++;
                                getPrinterStatus(this.timer1count);
                                if (this.timer1count = 1) {
                                    this.timer1count = 0;
                                }
                            }, 15000);
                        }
                    } else {
                        clearInterval(this.timer1);
                        this.timer1 = null;
                        this.$store.commit('updateBindStatua', 0);
                    }

                    this.tag_printers = res.data.tag_printer || [];
                    this.$store.commit('updateTagPrinter', this.tag_printers);
                    if (this.tag_printers.length > 0) {
                        if (this.tag_printers[0].printer_type == 4) {
                            this.timer2count = 0;
                            checkTagConnect();
                            setTimeout(() => {
                                this.timer2 = setInterval(async () => {
                                    this.timer2count++;
                                    checkTagConnect(this.timer2count);
                                    if (this.timer2count = 1) {
                                        this.timer2count = 0;
                                    }
                                }, 15000);
                            }, 1000);
                        } else if (this.tag_printers[0].printer_type == 3) {
                            getBleConnectStatus();
                        }
                    } else {
                        clearInterval(this.timer2);
                        this.timer2 = null;
                    }
                } else {
                    this.$toast(res.msg || '获取失败')
                }
            })
        },
        async handleReadyIos() {
            this.$store.commit('updateDeviceMac', device.uuid);
        },
    },
    async created() {
        clearInterval(this.timer1);
        this.timer1 = null;
        clearInterval(this.timer2);
        this.timer2 = null;
        if (this.isApp == 'android' || this.isApp == 'mt_android') {
            deviceInfo();
        } else if (this.isApp == 'windows') {
            await this.$device.getLocalIP();
        } else if(this.isApp == 'ios') {
            document.addEventListener("deviceready", this.handleReadyIos, false);
        }
        // console.log('this.$store.state.deviceMac', this.$store.state.deviceMac);
        this.getData();
        this.getPrintSetting();
        checkPrintType();
    },
    beforeDestroy() {
        clearInterval(this.timer1);
        this.timer1 = null;
        clearInterval(this.timer2);
        this.timer2 = null;
    }
}
</script>

<style lang="less" scoped>
.printset-switch-item {
    font-size: 2.6rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5rem 4rem 1rem 1rem;
    // border-bottom: 0.01rem solid #eeeeee;
    width: 100%;
    .printset {
        line-height: 2;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.thistag {
    color: #1588F5;
    border: 0.15rem solid #1588F5;
    padding: 0.5rem 2rem;
    border-radius: 0.8rem;
}
.nullStyle {
    padding: 4rem 0;
    text-align: center;
}
.switchnew {
    // background: #fff;
    // padding: 0.47rem 0.3rem;
    .qiantai {
        background: #fff;
        padding: 3rem 3rem;
        border-radius: 0.8rem;
    }

    .switchnew-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 0.01rem solid #eeeeee;
        padding-bottom: 3rem;
    }

    .switchnew-add-btn {
        background: #1588F5;
        color: #fff;
        border-radius: 0.8rem;
        padding: 0 2rem;
        height: 6rem;
        font-size: 2.4rem;
    }

    .printlist {
        margin-top: 1.5rem;
        // border-bottom: 0.01rem solid #eeeeee;
        .printitem {
            font-size: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0 0 0.7rem 0.7rem rgba(229,229,229,0.5);
            background: #fff;
            padding: 3rem 4rem;
            border-radius: 0.8rem;
            margin-bottom: 3rem;
        }
    }
}
.con {
    height: 100%;
}

.right {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
}
</style>
