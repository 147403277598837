<template>
    <div class="order-print flex bg-f4f4f4">
        <!-- 右边部分 -->
        <div class="right">
            <div class="type-head">
                <div class="flex" style="width: 100%">
                    <div
                        v-for="i in typeList"
                        :key="i.type"
                        @click="toogle(i)"
                        :class="{ ac: type == i.type }"
                        class="ab"
                    >
                        {{ i.name }}
                    </div>
                </div>
            </div>

            <div class="add-food">
                <van-form @submit="onSubmit" v-if="type == 1">
                    <van-field
                        v-model="o_password"
                        type="password"
                        name="o_password"
                        label="原店长密码"
                        placeholder="请输入原店长密码"
                        :rules="[{ required: true }]"
                    />
                    <van-field
                        v-model="password"
                        type="password"
                        name="password"
                        label="新店长密码"
                        placeholder="请输入新店长密码"
                        :rules="[{ required: true }]"
                    />
                    <div style="margin: 16px;" class="flex flex-jc">
                        <van-button round block type="info" native-type="submit">保 存</van-button>
                    </div>
                </van-form>
                <van-form @submit="onSubmit" v-if="type == 2">
                    <!-- <van-field
                        v-model="originPaw"
                        type="password"
                        name="originPaw"
                        label="原管理密码"
                        placeholder="请输入原管理密码"
                        :rules="[{ required: true }]"
                    /> -->
                    <van-field
                        v-model="newPaw"
                        type="password"
                        name="newPaw"
                        label="新管理密码"
                        placeholder="请输入新管理密码"
                        :rules="[{ required: true }]"
                    />
                    <div style="margin: 16px;" class="flex flex-jc">
                        <van-button round block type="info" native-type="submit">保 存</van-button>
                    </div>
                </van-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            originPaw: '', // 原管理密码
            newPaw: '', // 新管理密码
            typeList: [
                {
                    type: 1,
                    name: "店长密码",
                },
                {
                    type: 2,
                    name: "管理密码",
                },
            ],
            type: 1,
            o_password: '', // 原店长密码
            password: '', // 新密码
        };
    },
    methods: {
        toogle(i) {
            if (this.type != i.type) {
                this.type = i.type;
            }
        },
        // 提交
        onSubmit(e) {
            // 店长密码
            if (this.type == 1) {
                if (e.password.length < 6) {
                    this.$toast('密码最少6位')
                    return;
                }
                this.$dialog.confirm({
                    title: '修改店长密码',
                    message: '确认修改店长密码吗？',
                }).then(() => {
                    let data = {
                        o_password: this.o_password,
                        password: this.password
                    }
                    this.$api.dt_editShopPass(data).then(res => {
                        if (res.code == 1) {
                            this.$toast('保存店长密码成功，门店设置已更新');
                            this.$store.dispatch('getStoreInfo');
                            this.$router.replace({name: 'index'});
                        } else {
                            this.$toast(res.msg);
                        }
                    })
                })
            }
            // 管理密码
            else if (this.type == 2) {
                this.$dialog.confirm({
                    title: '修改管理密码',
                    message: '确认修改管理密码吗？',
                }).then(() => {
                    let data = {
                        manage_pass: this.newPaw
                    }
                    this.$api.dt_editManagePass(data).then(res => {
                        if (res.code == 1) {
                            this.$toast('保存管理密码成功，门店设置已更新');
                            this.$store.dispatch('getStoreInfo');
                            this.$router.replace({name: 'index'});
                        } else {
                            this.$toast(res.msg);
                        }
                    })
                })
            }
        }
    },
    created() {
        
    }
};
</script>

<style lang="less" scoped>

.right {
    flex: 1;
    margin-left: 0.3rem;
    padding-top: 0.2rem;
    margin-top: 1.2rem;
    .type-head {
        font-size: 2.6rem;
        display: flex;
        background-color: #fff;
        margin: 0 0 2rem;
        align-items: center;
        justify-content: space-between;

        .ab {
            // flex: 1;
            text-align: center;
            line-height: 8.5rem;
            height: 8.5rem;
            position: relative;
            font-weight: bold;
            margin-left: 2.5rem;
            width: 15%;
        }

        .ac::after {
            content: "";
            display: block;
            position: absolute;
            width: 12rem;
            height: 1.5rem;
            background: #1588F5;
            bottom: 0.5rem;
            left: calc(50% - 6rem);
            transform: translateY(-0.2rem);
            transition: 0.3s;
            animation: 0.8s widen;
        }
    }
}

.add-food {
    //border: 1px solid red;
    // height: calc(100vh - 1.2rem);
    // margin-top: 0.2rem;

    .van-cell.van-field:nth-child(-n + 8) {
        font-size: 2.6rem;

        /deep/.van-cell__title span {
            line-height: 6rem;
        }

        /deep/.van-field__control {
            height: 6rem;
        }
    }

    .van-button--info {
        width: 43rem;
        height: 7rem;
        background: #1588F5;
        border-radius: 0.8rem;
        border: 0;
    }
}
.van-button--normal {
    font-size: 2.4rem;
}

</style>
