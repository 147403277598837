<template>
    <div class="con">
        <titlebar :name="name" :is_custom_back="true" @goBackCustom="goBack" />
        <div class="right">
            <div class="type-head">
                <div class="flex" style="width: 100%">
                    <div
                        v-for="i in typeList"
                        :key="i.type"
                        @click="toogle(i)"
                        :class="{ 'ac': type == i.type }"
                        class="ab"
                    >
                        {{ i.name }}
                    </div>
                </div>
            </div>
            <template v-if="type == 1">
                <div class="smsr-top">
                    <span>当前可用短信数量</span>
                    <span class="ft-38 ft-bold mg-lt20 mg-rt20 color-1588F5">{{ able_num }}</span>
                    <span>条</span>
                    <button class="smsr-btn" @click="previewSms('smsRecharge')">充值</button>
                </div>
                <div class="switch">
                    <div class="switch-item">
                        <div>
                            <span class="mg-rt10">成为会员提醒</span>
                            <span class="ft-26 color-666">（当一名顾客开通门店会员时，会发送短信欢迎顾客）</span>
                        </div>
                        <div class="flex flex-ac">
                            <van-switch v-model="beMember" active-color="#1890FF" size="3.6rem" :disabled="shop.user_model != 1" @change="elecEvent(1, $event)"/>
                            <van-icon name="eye-o" color="#1588F5" size="3.5rem" class="mg-lt25" @click="previewSms('beMember')" />
                        </div>
                    </div>
                    <div class="switch-item">
                        <div>
                            <span class="mg-rt10">会员充值提醒</span>
                            <span class="ft-26 color-666">（会员充值成功后，会发送短信提醒顾客）</span>
                        </div>
                        <div class="flex flex-ac">
                            <van-switch v-model="memCharge" active-color="#1890FF" size="3.6rem" :disabled="shop.user_model != 1" @change="elecEvent(2, $event)"/>
                            <van-icon name="eye-o" color="#1588F5" size="3.5rem" class="mg-lt25" @click="previewSms('memCharge')" />
                        </div>
                    </div>
                    <div class="switch-item">
                        <div>
                            <span class="mg-rt10">会员消费提醒</span>
                            <span class="ft-26 color-666">（会员消费完成后，会发送短信提醒顾客）</span>
                        </div>
                        <div class="flex flex-ac">
                            <van-switch v-model="memRecord" active-color="#1890FF" size="3.6rem" :disabled="shop.user_model != 1" @change="elecEvent(3, $event)"/>
                            <van-icon name="eye-o" color="#1588F5" size="3.5rem" class="mg-lt25" @click="previewSms('memRecord')" />
                        </div>
                    </div>
                    <div class="switch-item">
                        <div>
                            <span class="mg-rt10">会员退款提醒</span>
                            <span class="ft-26 color-666">（会员订单退款成功后，会发送短信提醒顾客）</span>
                        </div>
                        <div class="flex flex-ac">
                            <van-switch v-model="memReturn" active-color="#1890FF" size="3.6rem" :disabled="shop.user_model != 1" @change="elecEvent(4, $event)"/>
                            <van-icon name="eye-o" color="#1588F5" size="3.5rem" class="mg-lt25" @click="previewSms('memReturn')" />
                        </div>
                    </div>
                    <!-- <div class="switch-item">
                        <div>
                            <span class="mg-rt10">优惠券提醒</span>
                            <span class="ft-26 color-666">（给会员发放优惠券后，会短信提醒顾客）</span>
                        </div>
                        <div class="flex flex-ac">
                            <van-switch v-model="coupon_msg" active-color="#1890FF" size="3.6rem" :disabled="shop.user_model != 1" @change="elecEvent(5, $event)"/>
                            <van-icon name="eye-o" color="#1588F5" size="3.5rem" class="mg-lt25" @click="previewSms('coupon_msg')" />
                        </div>
                    </div> -->
                    <div class="switch-item">
                        <div>
                            <span class="mg-rt10">等级变更提醒</span>
                            <span class="ft-26 color-666">（会员等级变更后，会发送短信提醒顾客）</span>
                        </div>
                        <div class="flex flex-ac">
                            <van-switch v-model="level_change" active-color="#1890FF" size="3.6rem" :disabled="shop.user_model != 1" @change="elecEvent(6, $event)"/>
                            <van-icon name="eye-o" color="#1588F5" size="3.5rem" class="mg-lt25" @click="previewSms('level_change')" />
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="type == 2">
                <div class="order-list">
                    <div style="height:calc(100% - 1.6rem);">
                        <table
                            width="100%"
                            id="table"
                            style="border-collapse: collapse;"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                        >
                            <thead bgcolor="#F2F2F2">
                                <tr
                                    bgcolor="#F2F2F2"
                                    class="thead-tr ft-22"
                                    style="height: 7.5rem;"
                                >
                                    <td
                                        :class="['color-333', 'ft-24', 'text-center', 'ft-bold', {'width25': index == 4}]"
                                        v-for="(item, index) in thead"
                                        :key="index"
                                    >{{item}}</td>
                                </tr>
                            </thead>

                            <tbody
                                v-if="contentList.length>0"
                                class="relative"
                            >
                                <tr
                                    v-for="item in contentList"
                                    :key="item.id"
                                    :class="['color-333','ft-24', 'text-center', 'tbody-tr']"
                                >
                                    <td>{{item.create_time}}</td>
                                    <td>{{item.sms_type | SmsType}}</td>
                                    <td>{{item.member_phone}}</td>
                                    <td>{{ item.able_num || 0 }}</td>
                                </tr>

                            </tbody>
                        </table>
                        <div
                            class="mg-tp30 content"
                            v-if="contentList.length === 0"
                        >
                            <div class="nullStyle">
                                <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="pagination" v-if="total > 0">
                    <van-pagination
                        class="pagination-foot"
                        v-model="page"
                        :total-items="total"
                        :items-per-page="limit"
                        mode="simple"
                        force-ellipses
                        style="background-color: #f2f2f2"
                        @change="getRecordData"
                    />
                </div>
            </template>
        </div>

        <popupOfDialog
            v-if="showDialog"
            :type="dialogType"
            :title="dialogTitle"
            :message="dialogMsg"
            :data="dialogData"
            :showCancelBtn="false"
            :showConfirmBtn="false"
            @closePopDialog="closePopDialog"
        ></popupOfDialog>
    </div>
</template>

<script>
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import { mapGetters } from 'vuex'
import titlebar from "@/views/component/titlebar";
export default {
    name: "setPrintType",
    components: {
        popupOfDialog,
        titlebar,
    },
    data() {
        return {
            name: 'smsSet',
            beMember: false,
            memCharge: false,
            memRecord: false,
            role_type: localStorage.role_type,
            memReturn: false,
            showDialog: false,
            dialogType: '',
            dialogTitle: '',
            dialogMsg: '',
            dialogData: {},
            able_num: 0,
            typeList: [
                {
                    type: 1,
                    name: "短信设置",
                },
                {
                    type: 2,
                    name: "短信记录",
                },
            ],
            type: 1,
            thead: [
                "时间",
                "短信类型",
                "会员电话",
                "当前可用短信数量"
            ],
            contentList: [],
            page: 1,
            limit: 6,
            total: 0,
            coupon_msg: false,
            level_change: false,
        }
    },
    computed: {
        ...mapGetters({ shop: 'shop' }),
    },
    filters: {
        // 1成为会员发送短信 2充值发送短信 3消费发送短信
        SmsType(val) {
            const obj = {
                1: '成为会员提醒',
                2: '会员充值提醒',
                3: '会员消费提醒',
                4: '会员退款提醒',
                5: '优惠券提醒',
                6: '等级变更提醒'
            }
            return obj[val] || ''
        }
    },
    methods: {
        goBack() {
            this.$router.replace({
                name: 'featureSet',
                query: {
                    name: 'featureSet',
                    active: this.$route.query.active,
                },
            })
        },
        toogle(i) {
            if (this.type != i.type) {
                this.type = i.type;
            }
        },
        // 预览
        previewSms(type) {
            switch (type) {
                case 'beMember':
                    this.dialogType = '4';
                    this.dialogTitle = '成为会员提醒预览';
                    this.dialogMsg = `【店掌宝】欢迎您加入XXXXX会员俱乐部，您的手机号与会员卡号已绑定成功`;
                    this.showDialog = true;
                    break;
                case 'memCharge':
                    this.dialogType = '4';
                    this.dialogTitle = '会员充值提醒预览';
                    this.dialogMsg = `【店掌宝】尊敬的会员XXX：您于05月02日12时34分在我店XXXXX充值200.00赠送20.00，当前卡上余额240.00`;
                    this.showDialog = true;
                    break;
                case 'memRecord':
                    this.dialogType = '4';
                    this.dialogTitle = '会员消费提醒预览';
                    this.dialogMsg = `【店掌宝】尊敬的会员XXX：您于05月02日12时34分在我店XXXXX消费成功，本次消费150.00，当前卡上余额100.00`;
                    this.showDialog = true;
                    break;
                case 'memReturn':
                    this.dialogType = '4';
                    this.dialogTitle = '会员退款提醒预览';
                    this.dialogMsg = `【店掌宝】尊敬的会员XXX：您于05月02日12时34分在我店XXXXX退款成功，本次退款150.00，当前卡上余额400.00`;
                    this.showDialog = true;
                    break;
                case 'smsRecharge':
                    this.dialogType = '2';
                    this.dialogTitle = '短信充值';
                    this.dialogMsg = '';
                    this.dialogData = {
                        'msgs': [
                            '请扫描下方二维码进行短信充值，短信费用0.1元/条，最低充值金额100元。',
                            '充值后预计1小时内到账，若超时未到账请联系您的客服经理。',
                        ],
                        'image': require('@/static/images/sms-recharge.jpg'),
                    };
                    this.showDialog = true;
                    break;
                case 'coupon_msg':
                    this.dialogType = '4';
                    this.dialogTitle = '优惠券提醒预览';
                    this.dialogMsg = `【店掌宝】[会员名称]：[门店名称]赠您一张5元优惠券，有效期至20XX-xx-xx，登录会员小程序可查看详情`;
                    this.showDialog = true;
                    break;
                case 'level_change':
                    this.dialogType = '4';
                    this.dialogTitle = '等级变更提醒预览';
                    this.dialogMsg = `【店掌宝】[会员名称]：您在[门店名称]的会员等级已由[原等级]提升至[新等级]，享更多会员权益`;
                    this.showDialog = true;
                    break;
            }
        },
        closePopDialog() {
            this.showDialog = false;
        },
        elecEvent(type, val) {
            console.log(type, val);
            let data = {
                sms_type: type,
                type: val ? 1 : 2,
            }
            this.$api.dt_setSmsConfig(data).then(res => {
                if (res.code == 1) {
                    this.$toast('设置成功，门店设置已更新');
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        getData() {
            this.$api.dt_getSmsSet().then(res => {
                if (res.code == 1) {
                    this.beMember = res.data.become_member == 1;
                    this.memCharge = res.data.member_recharge == 1;
                    this.memRecord = res.data.member_consume == 1;
                    this.memReturn = res.data.member_return == 1;
                    this.level_change = res.data.level_return == 1;
                }
            })
        },
        getRecordData() {
            this.$api.dt_getSmsRecord({
                page: this.page,
                limit: this.limit
            }).then(res => {
                if (res.code == 1) {
                    this.able_num = res.data.able_num || 0;
                    this.contentList = res.data.list || [];
                    this.total = parseInt(res.data.count) || 0;
                } else {
                    this.$toast(res.msg);
                    this.contentList = [];
                }
            })
        },
    },
    created() {
        this.getData();
        this.getRecordData();
    }
}
</script>

<style lang="less" scoped>
.width15 {
    width: 22%;
}
.width25 {
    width: 25%;
}
.ft-37 {
    font-size: 0.37rem;
}
.ft-51 {
    font-size: 0.51rem;
}
table thead {
    width: 100%;
    display: block;
    display: table;
    table-layout: fixed;
}

tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    vertical-align: middle;
}
table {
    background: white;
    display: table;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    tbody {
        overflow-y: scroll;
        display: block;
        height: calc(100vh - 50.5rem);
        width: 100%;
    }
    // tr:nth-last-child(1) {
    //     margin-bottom: 2rem;
    // }
    .tbody-tr {
        overflow-y: scroll;
        height: 1rem;
        width: 100%;
        border-bottom: 0.02rem rgba(0,0,0,0.06) solid;
        &.odd {
            background: #fef7ef;
        }
        td {
            height: 1.41rem;
        }
    }
}
.order-list {
    // height: 100%;
    // margin-left: 0.3rem;
    padding: 0 3rem;

    .tbody-tr {
        &.odd {
            background: #fef7ef;
        }
        td {
            height: 7.5rem;
        }
    }
}

.nullStyle {
    padding-top: 2rem;
    text-align: center;
    img {
        margin: 0 auto;
        width: 2.53rem;
        height: 1.77rem;
    }
}
.pagination {
  position: absolute;
  bottom: 3rem;
  right: 0;
  width: 30%;
}
/deep/.van-pagination__item--active {
  background-color: #1588F5;
}
.type-head {
    font-size: 2.6rem;
    display: flex;
    background-color: #fff;
    margin: 0 0 2rem;
    align-items: center;
    justify-content: space-between;

    .ab {
        // flex: 1;
        text-align: center;
        line-height: 8.5rem;
        height: 8.5rem;
        position: relative;
        font-weight: bold;
        // margin-left: 2.5rem;
        width: 15%;
    }

    .ac::after {
        content: "";
        display: block;
        position: absolute;
        width: 12rem;
        height: 1.5rem;
        background: #1588F5;
        bottom: 0.5rem;
        left: calc(50% - 6rem);
        transform: translateY(-0.2rem);
        transition: 0.3s;
        animation: 0.8s widen;
    }
}
.smsr-top {
    font-size: 2.6rem;
    display: flex;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229,229,229,0.5);
    background: #fff;
    padding: 2rem 2.5rem;
    border-radius: 0.8rem;
    margin: 2.5rem 3rem;
    .smsr-btn {
        font-size: 2.6rem;
        border-radius: 0.8rem;
        width: 12rem;
        height: 5.5rem;
        background: #1588F5;
        color: #fff;
        margin-left: 5rem;
    }
}
.mg-rt40 {
    margin-right: 0.6rem !important;
}
.printtest-btn {
    display: block;
    font-size: 0.4rem;
    width: 8.33rem;
    height: 1.27rem;
    border-radius: 0.13rem;
    margin: 1rem auto;
}

.con {
    height: 100%;
}

.right {
    background-color: #fff;
    height: calc(100% - 10rem);
    margin-top: 1.5rem;
    overflow-y: scroll;

    .switch {
        // margin-top: 0.2rem;
        background: #fff;
        padding-bottom: 1rem;

        .switch-item {
            font-size: 2.6rem;
            height: 9.5rem;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 4rem;
            border-bottom: 0.1rem solid #eeeeee;
        }

        .switch-item:last-child {
            border-bottom: none !important;
        }
    }
}
</style>
