<template>
	<div class="ratholOrChair">
		<img src="@/static/images/close.png" class="close" alt srcset @click="closePreview" />
		<div class="modal-content-box relative" style="z-index: 2002;">

			<div class="wrapper ft-24 width-58" v-if="receipttype == 1">

				<div class="printsize-tag">58mm</div>

				<div class="ft-50 ft-bold text-center">{{ shop.name }}</div>
				<div class="ft-30 text-center">☆☆结账单☆☆</div>

				<div>订单编号：123456789457894645</div>
				<div>收银人员：张XX</div>
				<div>打印时间：{{ now_time }}</div>

				<div class="thead">
					<div class="theadtitle theadtitle-line">名称</div>
					<div class="theadtitle theadtitle-line-1">单价</div>
					<div class="theadtitle theadtitle-line-1">数量</div>
					<div class="theadtitle theadtitle-line-1">小计</div>
				</div>

				<div class="mg-tp10 dish-box">
					<div>测试菜品</div>
					<div class="online-dish">
						<div class="onlinedish-item"></div>
						<div class="onlinedish-item">10.00</div>
						<div class="onlinedish-item">1</div>
						<div class="onlinedish-item">10.00</div>
					</div>
				</div>

				<div class="line"></div>
				<div class="mg-tp10 mg-bt10">订单合计：10.00</div>
				<div class="line"></div>
				<div class="mg-tp10 mg-bt10">
					<div>应收：10.00</div>
					<div class="ft-bold ft-34">实收(微信)：10.00</div>
				</div>

				<div class="line"></div>
				<div class="mg-tp10">电话：{{ shop.tel }}</div>
				<div>地址：{{ shop.xq }}</div>

			</div>

			<div class="wrapper ft-24 width-58" v-if="receipttype == 2">

				<div class="printsize-tag">58mm</div>

				<div class="ft-50 ft-bold text-center">{{ shop.name }}</div>
				<div class="ft-30 text-center">☆☆充值单☆☆</div>

				<div class="line"></div>
				<div>收银人员：张XX</div>
				<div>收银时间：{{ now_time }}</div>
				<div>充值单号：22030909552863</div>
				<div>会员姓名：李先生</div>
				<div>会员电话：135****6523</div>
				<div class="line"></div>
				<div>充值金额：100.00</div>
				<div>赠送金额：20.00</div>
				<div>充值前金额：32</div>
				<div>充值后金额：152.00</div>
				<div class="line"></div>
				<div class="ft-bold ft-34">实收(微信)：100.00</div>
				<div class="line"></div>
				<div>门店地址：{{shop.xq}}</div>
				<div>联系电话：{{shop.tel}}</div>

			</div>

			<div class="wrapper ft-24 width-58" v-if="receipttype == 3">

				<div class="printsize-tag">58mm</div>

				<div class="ft-50 ft-bold text-center">{{ shop.name }}</div>
				<div class="ft-30 text-center">☆☆交接班☆☆</div>

				<div class="line"></div>
				<div>员工：张XX</div>
				<div>开始时间：{{ now_time }}</div>
				<div>结束时间：{{ now_time }}</div>
				<div class="line"></div>
				<div class="ft-bold ft-34">总单数据</div>
				<div>门店：1</div>
				<div>退货：0</div>
				<div class="line"></div>
				<div class="ft-bold ft-34">总实收金额</div>
				<div>总实收金额：1.80</div>
				<div>商品销售实收：1.80</div>
				<div>会员充值：0</div>
				<div>线下核销收款：0</div>
				<div class="line"></div>
				<div class="ft-bold ft-34">总销售额</div>
				<div>微信：0</div>
				<div>现金：1.80</div>
				<div>收呗：0</div>
				<div>会员余额：0</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatTime } from '../../utils/util';
export default {
	props: {
		receipttype: {
			type: Number,
			default: '',
		},
		size: {
			type: Number,
			default: '',
		},
		data: {
			type: Object,
			default: {}
		},
	},
	data() {
		return {
			now_time: formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss'),
		};
	},
	async mounted() {
		// console.log('this.data', this.data, this.pointtype);
	},
	computed: {
		...mapGetters({ shop: 'shop' }),
	},
	methods: {
		closePreview() {
			this.$emit("closePreview");
		}
	}
};
</script>

<style lang="less" scoped>
.label-printsize-box {
	width: 6rem;
	height: 4rem !important;
	padding: 0.15rem 0.1rem !important;
}

* {
	box-sizing: border-box;
}

.line {
	border-top: 0.02rem rgba(0, 0, 0, 0.6) dashed;
}

.pd-bt49 {
	padding-bottom: 0.49rem;
}

.width-58 {
	width: 44rem;
}

.width-80 {
	width: 8rem;
}

.ratholOrChair {
	text-align: left;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-content-box {
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}

	.close {
		width: 4.6rem;
    height: 4.6rem;
    right: 4.5rem;
    top: 4.5rem;
		z-index: 1000;
		position: fixed;
	}

	.wrapper {
		padding: 3rem 1rem;
		position: relative;
		height: auto;
		line-height: 1.5;
		max-height: 75vh;
		overflow-y: scroll;
		background: #fff;

		.printsize-tag {
			position: absolute;
			top: 0;
			left: 0;
			width: 8rem;
			height: 8rem;
			border-radius: 50%;
			background: #1D5CFA;
			color: #fff;
			font-size: 2.2rem;
			text-align: center;
			line-height: 8rem;
		}

		.thead {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0.05rem;
			padding: 0.1rem 0;
			border-top: 0.02rem rgba(0, 0, 0, 0.6) dashed;
			border-bottom: 0.02rem rgba(0, 0, 0, 0.6) dashed;
		}

		.theadtitle:nth-child(1) {
			width: 40%;
		}

		.theadtitle:nth-child(2),
		.theadtitle:nth-child(3),
		.theadtitle:nth-child(4) {
			width: 20%;
		}

		.theadtitle-line {
			width: 20% !important;
		}

		.theadtitle-line-1 {
			width: calc(80% / 3) !important;
		}

		.dish-box .dish-item:nth-child(1) {
			width: 40%;
		}

		.dish-box .dish-item:nth-child(2),
		.dish-box .dish-item:nth-child(3),
		.dish-box .dish-item:nth-child(4) {
			width: 20%;
		}

		.online-dish {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.onlinedish-item:nth-child(1) {
				width: 20%;
			}

			.onlinedish-item:nth-child(2),
			.onlinedish-item:nth-child(3),
			.onlinedish-item:nth-child(4) {
				width: calc(80% / 3);
			}
		}

		.bill-value {
			margin-right: 12%;
		}

		.two-thead {
			width: 50%;
		}
	}
}
</style>
