<template>
  <div class="page_system">
    <!-- 标题 -->
    <div class="system_title">
      <div>选择规格</div>
      <img
        src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png"
        @click="closeMenu"
      />
    </div>
    <!-- 选项 -->
    <!-- <div class="machine"> -->
    <van-radio-group v-model="radio" @change="choose($event)">
      <van-radio name="1" :class="radio == 1 ? 'bg_FFF6F0' : ''"
        >主机</van-radio
      >
      <van-radio name="2" :class="radio == 2 ? 'bg_FFF6F0' : ''"
        >从机</van-radio
      >
    </van-radio-group>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    sendLocation:String
  },
  data() {
    return {
      radio: this.sendLocation=="主机"? "1":"2", // 功能设置选项
    };
  },
  mounted(){
    console.log(this.radio,"radio")
  },
  methods: {
    // 关闭菜单弹窗
    closeMenu() {
      this.$emit("handlePopOpen", false);
    },
    // 选择
    choose(e) {
      console.log("选择===", e);
      // this.radio = e.name;
      if (this.radio != "") {
        this.$emit("handlePopOpen", e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page_system {
  width: 88.3rem;
  height: 48rem;
  background: #ffffff;

  .system_title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 4.6rem 3.1rem 4.6rem;
    height: 12.5rem;
    border-bottom: 0.1rem solid #e5e5e5;
    font-size: 3.6rem;
    font-weight: bold;
    color: #333333;
    img {
      width: 4.3rem;
      height: 4.3rem;
      // text-align: end;
      margin-left: 29.4rem;
    }
  }

  .machine {
    width: 88.3rem;
    height: 8.4rem;
    border: 0.1rem solid #e5e5e5;
  }

  .bg_FFF6F0 {
    background: #fff6f0 !important;
    border-bottom: 0.1rem solid #e5e5e5 !important;
  }

  .van-radio {
    width: 100%;
    height: 8.4rem;
    border-bottom: 0.1rem solid #e5e5e5;
    padding-left: 10.1rem;

    /deep/.van-radio__label {
      margin-left: 4.3rem;
      font-size: 2.8rem;
      font-weight: bold;
      color: #333333;
      line-height: 4rem;
    }

    /deep/.van-radio__icon--checked .van-icon {
      color: #fff;
      background-color: #1588F5;
      border-color: #1588F5;
      width: 2.6rem;
      height: 2.6rem;
    }

    /deep/.van-radio__icon--round .van-icon {
      width: 2.6rem;
      height: 2.6rem;
    }

    /deep/.van-icon-success::before {
      content: "\F0C8";
      margin-top: 0.2rem;
    }
  }
}
</style>
