<template>
    <div class="right">
        <div class="type-head">
            <div class="flex" style="width: 100%">
                <div
                    v-for="i in typeList"
                    :key="i.type"
                    @click="toogle(i)"
                    :class="{ 'ac': type == i.type }"
                    class="ab"
                >
                    {{ i.name }}
                </div>
            </div>
        </div>

        <div class="add-food" v-if="type == 1">
            <div class="flex flex-column flex-ac">
                <img src="@/static/dzb.png" class="logo" />
                <div class="ft-30 color-333">店掌宝·会员收银</div>
                <div class="ft-24 color-333 mg-tp5 text-center">
                    <span>V {{ nowVersion }}</span>
                    <a target="_blank" href="https://www.dianzhangbao.net/DownloadCenter/" class="downloadtext">更新</a>
                </div>
            </div>
            <div class="ft-30 color-333 mg-tp40">版本记录</div>
            <ul class="list-body mg-tp20">
                <li class="list-head">
                    <div>版本</div>
                    <div style="flex: 3;">内容</div>
                    <div>更新时间</div>
                </li>
                <li class="scrollY">
                    <div :class="['list-worker']" v-for="(item, index) in contentList" :key="index">
                        <div class="list-td">{{ item.vesion }}</div>
                        <div class="list-td pd-lt20 pd-rt20" style="flex: 3;">
                            <ul class="width-full version-list">
                                <li v-for="(ele, i) in item.content" :key="i" class="text-left width-full">{{ ele || '无' }}</li>
                            </ul>
                        </div>
                        <div class="list-td">{{ item.create_time }}</div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="add-food" v-if="type == 2">
            <div class="contact-box">
                <div class="contact-data">
                    <div class="flex flex-ac">
                        <div class="flex flex-column flex-ac" style="margin-right: 15rem;">
                            <img class="contact-wechat" src="@/static/images/contact-kf.png" />
                            <span>联系微信客服</span>
                        </div>
                        <div class="flex flex-column flex-ac">
                            <img class="contact-wechat" src="@/static/images/video-tutorial.png" />
                            <span>观看视频教程</span>
                        </div>
                    </div>
                    <div class="flex flex-ac phone-cell">
                        <img src="@/static/images/contact1-web.png" class="webimg mg-rt25" />
                        <span class="color-666">官网链接：</span>
                        <a :href="serverInfo.official_link && serverInfo.official_link != '' ? serverInfo.official_link : 'https://dianzhangbao.net/'" target="_blank"><span class="color-333 ft-22 text-underline">{{serverInfo.official_link || 'https://dianzhangbao.net/'}}</span></a>
                    </div>
                    <div class="flex flex-ac">
                        <van-icon name="clock" color="#666" size="3.2rem" class="mg-rt25" />
                        <span class="color-666">服务时间：</span>
                        <span class="color-333">{{ serverInfo.service_time || '暂无' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            now_vesion: '', // 当前版本
            contentList: [
                {
                    content: ['1', '2'],
                }
            ],
            typeList: [
                {
                    type: 1,
                    name: "版本记录",
                },
                {
                    type: 2,
                    name: "联系我们",
                },
            ],
            type: 1,
            serverInfo: {}
        };
    },
    computed: {
        nowVersion() {
            return this.$store.state.nowVersion;
        }
    },
    methods: {
        getData(){
            // this.$api.getVesion().then(res => {
            //     if (res.code == 1) {
            //         this.contentList = res.data.list || [];
            //     } else {
            //         this.$toast(res.msg);
            //     }
            // })
        },
        toogle(i) {
            if (this.type != i.type) {
                this.type = i.type;
            }
        },
        getServerData(){
            // this.$api.getServerInfo().then(res=> {
            //     if (res.code == 1) {
            //         this.serverInfo = res.data[0];
            //     } else {
            //         this.$toast(res.msg);
            //     }
            // })
        }
    },
    created() {
        this.$bus.$emit('updateMenuActive', 27);
        this.type = this.$route.query.type || 1;
        this.getData();
        this.getServerData();
    }
};
</script>

<style lang="less" scoped>
.contact-box {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8rem;
    font-size: 2.2rem;
    height: 100%;

    .contact-data {
        line-height: 7rem;
    }
    .contact-wechat {
        width: 17rem;
        height: 17rem;
        margin-bottom: 1.5rem;
    }

    .phone-cell {
        margin-top: 6rem;
        .webimg {
            width: 3rem;
            height: 3rem;
        }
    }
}

.right {
    width: 100%;
    height: 100%;
    background: #fff;

    .type-head {
        font-size: 2.6rem;
        display: flex;
        background-color: #fff;
        align-items: center;
        justify-content: space-between;

        .ab {
            // flex: 1;
            text-align: center;
            line-height: 8.5rem;
            height: 8.5rem;
            position: relative;
            font-weight: bold;
            margin-left: 2.5rem;
            width: 15%;
        }

        .ac::after {
            content: "";
            display: block;
            position: absolute;
            width: 12rem;
            height: 1.5rem;
            background: #1588F5;
            bottom: 0.5rem;
            left: calc(50% - 6rem);
            transform: translateY(-0.2rem);
            transition: 0.3s;
        }
    }
}

.add-food {
    height: calc(100vh - 25rem);
    // margin-top: 0.2rem;
    background: #fff;
    line-height: 1.7;
    font-size: 2.4rem;
    padding: 3rem;
    overflow-y: scroll;

    .logo {
        width: 13rem;
        height: 13rem;
        margin-bottom: 2rem;
        box-shadow: 0 0 0.7rem 0.7rem rgba(229,229,229,0.26);
        border-radius: 0.8rem;
    }

    .list-body {
        .list-head {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            background-color: #F2F2F2;
            color: #333;
            font-size: 2.4rem;
            height: 7.5rem;
            line-height: 7.5rem;

            div {
                flex: 1;
                text-align: center;
                font-weight: bold;
            }
        }
        .scrollY {
            // overflow-y: scroll;

            .version-list li {
                list-style-type: disc;
            }

            .list-worker {
                display: flex;
                justify-content: space-between;
                font-size: 2.4rem;
                min-height: 7.5rem;
                border-bottom: 0.15rem #dfdbdb solid;
                padding: 1.5rem 0;

                .list-td {
                    line-height: 4.5rem;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

    .downloadtext {
        font-size: 2.4rem;
        background: #1588F5;
        color: #fff;
        border-radius: 0.8rem;
        text-align: center;
        padding: 0.3rem 3.5rem;
        margin-top: 1rem;
        display: block;
    }
}

</style>
